import {
  InstrumentAction,
  InstrumentEvent,
  InstrumentStatus,
  InstrumentTagType,
  InstrumentUploaderType,
} from '@finverity/graphql';
import { FinButtonAppearance } from '@finverity/ui-kit';

import { InstrumentApprovalConfigs, InstrumentApprovalMakerSubscriptionEvents } from '../../../types';
import { InstrumentApprovalMakerDataGridColId } from '../../instrument-approval-maker-container/constants';

export enum PayerFinanceRequestMakerFilterIdentifier {
  PendingPayerFinanceRequestMaker = 'PendingPayerFinanceRequestMaker',
  NotFundable = 'NOT_FUNDABLE',
}

export const PAYER_INSTRUMENT_FINANCE_REQUEST_MAKER_CONFIGS: InstrumentApprovalConfigs = {
  companyType: InstrumentUploaderType.Payer,
  pageTitle: 'Payer Finance Request (Maker)',
  actionsDefs: [
    {
      buttonText: 'Review & Approve',
      buttonSvgIconName: 'fvIconInstrumentApprovalReview',
      buttonAppearance: FinButtonAppearance.Primary,
      actionType: InstrumentAction.PayerFinanceMakerApprove,
      disabled: true,
      disabledTooltip: null,
    },
    {
      buttonText: 'Reject',
      buttonSvgIconName: 'fvIconInstrumentApprovalReject',
      buttonAppearance: FinButtonAppearance.OutlineAccent,
      actionType: InstrumentAction.PayerFinanceMakerReject,
      disabled: true,
      disabledTooltip: null,
    },
  ],
  filtersDefs: [
    {
      identifier: PayerFinanceRequestMakerFilterIdentifier.PendingPayerFinanceRequestMaker,
      label: 'Pending Payer Finance Request (Maker)',
      params: {
        statuses: [InstrumentStatus.PayerFinancePendingMakerConfirmation],
        tags: {
          notIncludes: [
            InstrumentTagType.Deleted,
            InstrumentTagType.NotFundable,
            InstrumentTagType.NoEligibleFundingWindow,
          ],
        },
      },
      count: 0,
      hiddenColumns: [],
    },
    {
      identifier: PayerFinanceRequestMakerFilterIdentifier.NotFundable,
      label: 'Not Fundable',
      params: {
        statuses: [InstrumentStatus.PayerFinancePendingMakerConfirmation],
        tags: {
          notIncludes: [InstrumentTagType.Deleted],
          includes: [InstrumentTagType.NotFundable, InstrumentTagType.NoEligibleFundingWindow],
        },
      },
      count: 0,
      hiddenColumns: [
        InstrumentApprovalMakerDataGridColId.DisbursementDate,
        InstrumentApprovalMakerDataGridColId.Tenor,
        InstrumentApprovalMakerDataGridColId.RepaymentDate,
        InstrumentApprovalMakerDataGridColId.AdvanceRate,
        InstrumentApprovalMakerDataGridColId.PrincipalAmount,
        InstrumentApprovalMakerDataGridColId.DisbursementAmount,
        InstrumentApprovalMakerDataGridColId.RepaymentAmount,
        InstrumentApprovalMakerDataGridColId.NetUnfinancedBalance,
        InstrumentApprovalMakerDataGridColId.SellerFeesInAdvance,
        InstrumentApprovalMakerDataGridColId.PayerFeesInAdvance,
        InstrumentApprovalMakerDataGridColId.SellerFeesInArrears,
        InstrumentApprovalMakerDataGridColId.PayerFeesInArrears,
        InstrumentApprovalMakerDataGridColId.TotalSellerFees,
        InstrumentApprovalMakerDataGridColId.TotalPayerFees,
        InstrumentApprovalMakerDataGridColId.TotalFees,
      ],
    },
  ],
  subscriptionEvents: <InstrumentApprovalMakerSubscriptionEvents>{
    approvedSuccess: [InstrumentEvent.PayerFinanceCheckerPending],
    approvedFailure: [InstrumentEvent.PayerFinanceMakerPending],
    rejected: [InstrumentEvent.PayerFinanceMakerRejected],
    updated: [InstrumentEvent.InstrumentEdited],
    removed: [InstrumentEvent.InstrumentDeleted],
  },
};
